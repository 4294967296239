import { updateServerQuestionScore, fetchInitialData} from './ApiHelpers';
import { updateQuestionScore, saveInitialData, updateLessonProgress,  getUserId}  from './DbHelpers';

// Fetch Levels
export const processAnswer = async (question_cp, unit, user_id) => 
{
  try
  {
    //Push Answer to Server
    question_cp.is_send_to_server = 1
    const cp_date = new Date().toISOString().slice(0, 19).replace('T', ' ');
    question_cp.response_datetime = cp_date

    var isServerUpdated = await updateServerQuestionScore(question_cp, user_id)        
    if(!isServerUpdated)
    {
        //Try again to update
        isServerUpdated = await updateServerQuestionScore(question_cp, user_id)
        if(!isServerUpdated)
        {
            //Flag as it was not send to server
            question_cp.is_send_to_server = 1
            console.log("Updating Answer on server failed")
        }
    }
    
    var isUpdated = await updateQuestionScore(question_cp)
    if(!isUpdated)
    {
        //Try again to update
        isUpdated = await updateQuestionScore(question_cp)
        if(!isUpdated)
        {
          console.log("Updating Answer Failed")
        }
    }

    //Update Lesson Progress
    await updateLessonProgress(question_cp, user_id, unit)

  } 
  catch (e) 
  {
    console.log('Failed fetching levels', e.message); // handle error
    return false
  }
}

// Fetch Levels
export const syncAndUpdate = async () => 
{
   
  try
  {
    const user_id       =   await getUserId(getUserId)
    const data_fetched  =   await fetchInitialData(user_id)
    if(data_fetched)
    {
      return await saveInitialData(data_fetched)      
    }
  } 
  catch (e) 
  {
    console.log('Failed synd and update', e.message); // handle error
    return false
  }
}