import React from 'react';
import { useHistory  } from 'react-router-dom'
import { useState, useEffect } from 'react'
 

import { saveInitialData, readAppState, checkDatabase, getUserId } from '../utils/DbHelpers';
import { fetchInitialData} from '../utils/ApiHelpers';
import { syncAndUpdate}  from '../utils/ProjectFunctions';
import ProfileUi from 'react-profile-card';
import logo from '../assets/logo.png';
import ReactSwipeButton from 'react-swipe-button';
import Confetti from 'react-confetti'
import useWindowSize from "react-use/lib/useWindowSize"

const LoadingButton = (props) => 
{
    const [isLoading, setLoading] = useState(false);
    const history = useHistory()
    const [errorMessage, setErrorMessage] = useState(null)
    const search = window.location.search;
    const params = new URLSearchParams(search);
  
    useEffect(() => 
    {
      const appWarmUp = async () => 
      {
        
        //Check Initial Stage of Database
        const isCheckDb = await checkDatabase()

        if(isCheckDb)
        {
          
          //Try to Sync and Update
          await syncAndUpdate();

          //Check Last App state
          const appState = await readAppState()          
          if(appState)
          {
              const screen        =   appState[0].message.screen
              const level_id      =   appState[0].message.level
              const unit_id       =   appState[0].message.unit

              if (level_id == null || unit_id == null) {
                history.push(`/levels/1/units/1/lessons/`) 
                return;
              }
              
              if(screen === 'questions')
              {
                  history.push(`/levels/${level_id}/units/${unit_id}/lessons/`) 
                  return;
              }

              if(screen === 'lessons')
              {
                history.push(`/levels/${level_id}/units/${unit_id}/lessons/`) 
                return;
              }
            
              if(screen === 'units')
              {
                  if(unit_id)
                    history.push(`/levels/${level_id}/units/${unit_id}/lessons`)  
                  else 
                    history.push(`/levels/${level_id}/units`)  
                  return;
              }
            
              if(screen === 'levels')
              {
                  if(level_id)
                    history.push(`/levels/${level_id}/units`)  
                  else 
                    history.push(`/levels`)  

                  return;
              }
          }
          else
          {
              history.push("/levels/1/units/1/lessons")
          }
        }
        
        if (isLoading) 
        {
          try 
          {
            
            //Fetch Initial Data From Server and Save it locally
            const user_id     = await getUserId(getUserId)
            const data_fetched = await fetchInitialData(parseInt(user_id))
            if(data_fetched)
            {
              // Make sure user id is an int and not a string as it causes problems down the road
              data_fetched.initial_state.user_id = parseInt(data_fetched.initial_state.user_id);
              await saveInitialData(data_fetched)
            
              history.push("/levels/1/units/1/lessons")
              return;
            }
            else
            {
              setErrorMessage("Something went wrong. We are working to fix it!")
              setLoading(false)
            }
          } 
          catch (error) 
          {
            setErrorMessage("Something went wrong. We are working to fix it(2)!")            
            setLoading(false)
          }
        }  
      }
      appWarmUp()
    }, [props, isLoading, history])
    
    const handleClick = () => 
    {
      setErrorMessage("")
      setLoading(true)
    }

    const { width, height } = useWindowSize();

    return (
      <div style={{color:"#00005c", fontSize:"24px", width:"250px", height:"75px",   marginLeft: "calc(50% - 125px)"}}>        
        <ProfileUi 
            imgUrl={logo}
            name={params.get('name')}
            designation='Welcome to English Academy'
        />
        <ReactSwipeButton 
          text='Start →'
          color='linear-gradient(45deg, #FE6B8B 30%, #FF8E53 90%)'
          onSuccess={()=>{handleClick()}}
        />
        <div
          disabled={isLoading}
          onClick={!isLoading ? handleClick : null}
          style={{ color:"#FE6B8B", height:"1px", paddingTop: "60px", marginLeft: "-30px"}}
        >
        {isLoading ? <Confetti width={width} height={height}/> : '' }
        </div>
        {errorMessage ? <p className="text-danger">{errorMessage}</p> : '' }
      </div>
    );
  }

  export default LoadingButton
