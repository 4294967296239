import axios from 'axios';
import {  API_BASE_URL, POST_ANSWER_RESPONSE, INITIAL_DATA_URL, LESSON_DATA_URL } from '../constants/constants';

// Update questions
export const updateQuestions = async (data) =>
{
  console.log(data.questionType);
  var headers = {
      'Content-Type': 'multipart/form-data'
  }

  axios.post(API_BASE_URL + '/update_question/', data, { 
      headers: headers
  }).then((res) => {
      console.log(res);
  }).catch((err) => {
      console.log(err);
  })
}

// Fetch Levels
export const fetchInitialData = async (user_id) => 
{
  try
  {
    const res = await fetch(`${INITIAL_DATA_URL}/${user_id}`)
    const data = await res.json()   
    if(data.success === "ok")
      return data
    else
      return false
  } 
  catch (e) 
  {
    console.log('Failed fetching levels', e.message); // handle error
    return false
  }
}

// Fetch Levels
export const fetchUnitLessons = async (unit_id, lesson_id, user_id) => 
{
  try
  {
    const res = await fetch(`${LESSON_DATA_URL}/${user_id}/${unit_id}/${lesson_id}`)
    const data = await res.json()
    if(data.success === "ok")
      return data
    else
      return false
  } 
  catch (e) 
  {
    console.log('Failed fetching levels'); // handle error
    return false
  }
}

// Update Question
export const updateServerQuestionScore = async (question_data, user_id) => 
{
  try
  {
      question_data.user_id = user_id
      return await axios({
        method: 'post',
        url: POST_ANSWER_RESPONSE,
        data: question_data
      })
      .then(response => {
        return true
      })
      .catch(response =>
      {
        return false
      });
  } 
  catch (e) 
  {
    console.log('Failed udpating server for answer'); // handle error
    return false
  }
}

export const getUserLessonProgress = async (user_id, lesson_id) => 
{
  try
  {
    const res = await fetch(`${API_BASE_URL}/users/${user_id}/progress/${lesson_id}`)
    const data = await res.json()
    if(data.success === "ok")
      return data
    else
      return false
  } 
  catch (e) 
  {
    console.log('Failed fetching levels'); // handle error
    return false
  }
}

export const getLessonS3Data = async (path) => 
{
  try
  {
    const res = await fetch(path)

    if (res.status === 403)
      return false;
    
    const data = await res.json()

    if(data)
      return data
    else
      return false
  } 
  catch (e) 
  {
    console.log(e); // handle error
    return false
  }
}

export const getSubtitlesS3Data = async (path) => 
{
  try
  {
    const res = await fetch(path)

    if (res.status === 403)
      return false;

    const data = await res.text()

    if(data)
      return data
    else
      return false
  } 
  catch (e) 
  {
    console.log(e); // handle error
    return false
  }
}



// ***********************************************
// ***********************************************
//
//    FOLLOWING APIS ARE NOT IN USE NOW. But can be used in future. 
// 
// ***********************************************
// ***********************************************

// Fetch Levels
export const fetchLevels = async () => 
{
  try
  {
    const res = await fetch(`${API_BASE_URL}/get_levels`)
    const data = await res.json()
    return data
  } 
  catch (e) 
  {
    console.log('Failed fetching levels'); // handle error
  }
}

// Fetch Units
export const fetchUnits = async (level_id) => 
{
  try
  {
    const res = await fetch(`${API_BASE_URL}/get_units/$(level_id)`)
    const data = await res.json()
    return data
  } 
  catch (e) 
  {
    console.log('Failed fetching units'); // handle error
  }
}


// Fetch Lessons
export const fetchLessons = async (unit_id) => 
{
  try
  {
    const res = await fetch(`${API_BASE_URL}/get_lessons/${unit_id}`)
    const data = await res.json()
    return data
  } 
  catch (e) 
  {
    console.log('Failed fetching units'); // handle error
  }
}

// Fetch Lessons
export const fetchQuestionsAndResponses= async (lesson_id) => 
{
  try
  {
    const res = await fetch(`${API_BASE_URL}/get_question_and_responses/${lesson_id}`)
    const data = await res.json()
    return data
  } 
  catch (e) 
  {
    console.log('Failed fetching units'); // handle error
  }
}