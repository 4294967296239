import { useHistory  } from 'react-router-dom'
import { useState, useEffect,   useRef } from 'react'

import {Container , Row, Col, Button, Alert } from 'react-bootstrap';
import { FaArrowRight, FaArrowLeft } from 'react-icons/fa'
import '../levels.scss';

import { getLessonS3Data }  from '../utils/ApiHelpers';
import { ASSETS_BASE_PATH  } from '../constants/constants';
import { getLessonSortOrder, getUserLocalization } from '../utils/DbHelpers';
import Img from '../assets/play_audio.png';

const Chant = (props) => 
{
  const history = useHistory()  

  const [errorMessage, setErrorMessage] = useState('');    
  const [stimData, setStimData] = useState([]);    
  const [stimImage, setStimImage] = useState();
  const [stimAudio, setStimAudio] = useState('');
  const [audioDone, setDone] = useState(false);

  const [audioPlaying, setAudioPlaying] = useState(false);
  const refImage = useRef(null)

  const [lessonAssetPath, setLessonAssetPath] = useState('');
  const [localization, setLocalization] = useState('');

  useEffect(() => 
  {
    const init = async () => 
    {
      let lesson_id       =   parseInt(props.match.params.lesson_id);
      const level         =   parseInt(props.match.params.level_id);
      const unit          =   parseInt(props.match.params.unit_id);

      let localLessonId = await getLessonSortOrder(lesson_id, unit);
      setLessonAssetPath(ASSETS_BASE_PATH + `/level-${level}/unit-${unit}/lesson-${localLessonId}/`);

      let local = await getUserLocalization();
      setLocalization(local);

      if(parseInt(lesson_id) > 0)
      {
          const chantInfo = await getLessonS3Data(ASSETS_BASE_PATH + `/level-${level}/unit-${unit}/lesson-${localLessonId}/audio/chant.json`);
          let chant_data = null;

          if (chantInfo === false) {
            // Go to next lesson if chant data isn't available
            lesson_id += 1;
            
            history.push(`/levels/${level}/units/${unit}/lessons/${lesson_id}/welcome`);
            return;
          }
          else {
            chant_data = chantInfo;
          }

          const stim_images = chant_data.images
          const stim_audio = chant_data.audiosrc
          const stim_image = chant_data.coverimg

          if(stim_images.length > 0) 
          {
            setStimImage(stim_image);
            setStimData(chant_data);
            setStimAudio(stim_audio);
          }

          // window.timeout = setTimeout(function () 
          // {
          //   setImageHeight(refImage.current.clientHeight)             
          // }, 500);
      }        
      else
        setErrorMessage("Invalid Lesson. Please select valid lesson")
    }
    init()
  }, [history, props])
  
  const onSkipClicked = async () => 
  {
    if(audioPlaying === true)
    {
      let audio = document.getElementById("stim-audio");
      audio.pause();
    }

    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    let finishedUnit = false;

    if (urlParams.get("finished-unit")) {
      finishedUnit = true;
    }

    if (finishedUnit) {
      history.push(`/levels/${parseInt(props.match.params.level_id)}/units/${parseInt(props.match.params.unit_id)}/lessons/?review=true&finished_unit=true`);
    }
    else {
      history.push(`/levels/${parseInt(props.match.params.level_id)}/units/${parseInt(props.match.params.unit_id)}/lessons/?review=true`);
    }
  }
  
  function onPlayStopped()
  {
    if (audioPlaying) {
      setAudioPlaying(false)
      let audio = document.getElementById("stim-audio");
      audio.pause();
      
      clearTimeout(window.timeout)
      setStimImage(stimData.coverimg)
    }
  }

  function onPlayClicked() 
  {
    setAudioPlaying(true);
    let audio = document.getElementById("stim-audio");
    audio.play();
  }

  const onAudioUpdate = () => {
    let audio = document.getElementById("stim-audio");
    let currentTime = parseFloat(audio.currentTime);
    let currentIndex = 0;

    for (let i = 0; i < stimData.images.length; ++i) {
      if (currentTime >= parseFloat(stimData.images[i].time)) {
        currentIndex = i;
      }
    }

    setStimImage(stimData.images[currentIndex].image);
  }

  const onAudioEnd = () => {
    setAudioPlaying(false);
    setDone(true);
  }

  function onBackClicked() {
    history.goBack();
  }
  
  if(!stimImage)
    return null;

//   const RawHTML = ({children, className = ""}) => 
// < div className={className}
//   dangerouslySetInnerHTML={{ __html: children.replace(/\n/g, '<br />')}} />

  return (
    <Container style={{display: "flex", flexDirection: "column", justifyContent: "space-between"}}>  
      {errorMessage &&
        <Row key="r0"> 
            <Col key="r0-c1">
                <Alert variant='danger'>
                    {errorMessage}
                </Alert>
            </Col>  
        </Row>
      }
      {stimData &&
      <Row key="r1" className="video-container">
        <audio id="stim-audio" onTimeUpdate={onAudioUpdate}  onEnded={onAudioEnd}>
          <source src={lessonAssetPath + `audio/${stimAudio}.mp3`} type="audio/mp3" />
        </audio>
        <Col xs={12} lg={9}  key="r1-c1" style={{padding:"0px"}}>
           <div ref={refImage} className="lesson-welcome-images" onClick={onPlayStopped}>
              <img src={`${lessonAssetPath}images/${stimImage}`} alt="Intro" style={{width: "100%", height: "100%" }} />
              {!audioPlaying && 
              <button className="play-btn" onClick={onPlayClicked}>
                <img className="play-btn-img" src={Img} alt="#" />
              </button>}
           </div>
        </Col>
        {/* <Col xs={12} lg={6}  key="r1-c2" style={{padding:"0px"}}>
           <div className="lesson-welcome-script" style={{minHeight: imageHeight, objectFit: "contain"}}>
              {audioPlaying &&
                <RawHTML>{stimScript}</RawHTML>
              }
              {!audioPlaying &&
                  <img src={StimCover} alt="Intro" style={{width: "100%" }} />
              }
           </div>
        </Col> */}
      </Row>
      }  
      <Row key="r2" className="nav-container-footer">
         <Col xs={6} md={6} key="r2-c22" className="skip-btn-container" style={{textAlign:"center"}}>
          <Button
            variant="warning"            
            onClick={onBackClicked}
            className="lesson-stim-button"
            >
              <>
                <FaArrowLeft></FaArrowLeft> {localization.Back} 
              </>
          </Button>
         </Col>
         <Col xs={6} md={6} key="r2-c21" className="skip-btn-container" style={{textAlign:"center"}}>
          <Button
            variant="warning"            
            onClick={onSkipClicked}
            className="lesson-stim-button"
            >
              {audioDone &&
                <>
                  {localization.Next} <FaArrowRight className={"arrow-bounce"}></FaArrowRight>
                </>
              }
              {!audioDone &&
                <>
                  {localization.Skip} <FaArrowRight></FaArrowRight>
                </>
              }
          </Button>
         </Col>
      </Row>  
    </Container>
  )
}
 
export default Chant
