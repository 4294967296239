import { useHistory  } from 'react-router-dom'
import {Row, Col} from 'react-bootstrap';
import '../levels.scss';

import long_textholder from '../assets/long_textholder.png';
import lessons_button from '../assets/lessons_button.png';
import toggle_button from '../assets/lesson_button.png';
import { useState, useEffect } from 'react'
import {getUserLocalization}  from '../utils/DbHelpers';

const headerSideButtons  = 
{
    backgroundImage: `url(${long_textholder})`,
};
 
const headerDisabledSideButtons =
{
    backgroundImage: `url(${long_textholder})`,
};

const headerMiddleButton  = 
{
    backgroundImage: `url(${lessons_button})`,
};

const Header = ({level_id, unit_id, lesson_id}) => 
{
    const history = useHistory();
    const [showHeader, setshowHeader] = useState(false);  
    const [localization, setLocalization] = useState('');

    useEffect(() => 
    {
        let isMounted = true;

        const init = async () => 
        {          
            let local = await getUserLocalization();
            
            if (isMounted)
                setLocalization(local);
        }
        init();

        return () => {
            isMounted = false;
        };
    }, []);

    function OnLevelClick (level_id, unit_id) 
    {
        history.push(`/levels`) 
    } 

    function OnUnitClick(level_id, unit_id) 
    {
        history.push(`/levels/${level_id}/units`) 
    } 

    function OnLessonButtonClick (level_id, unit_id) 
    {
        history.push(`/landing`) 
    } 

    function OnLessonClick(level_id, unit_id)  
    {
        history.push(`/levels/${level_id}/units/${unit_id}/lessons`)
    }

    function toggleHeader() {
        setshowHeader(!showHeader);
    }
    
    return (
        <div>
            <div className="header-toggle audio-button" onClick={() => toggleHeader()}>
                <img alt="header toggle button" src={toggle_button}/>
            </div>
            {showHeader && <Row className="header-block" key="r1" >
                <Col xs={12} lg={4}  className="header-first ">
                    <div className="header-first-btn" >
                        { level_id ?
                        <div className="header-side-buttons" style={headerSideButtons} onClick={() => OnLevelClick(level_id, unit_id)}>{localization.Level}-{level_id}</div>
                        :
                        <div className="header-side-buttons  header-disabled-button" style={headerSideButtons}>{localization.Levels} </div>
                        } 
                    </div>
                </Col>
                <Col xs={12} lg={4} className="header-middle">
                    <div className="header-middle-btn" >
                    { lesson_id ?
                        <div  className="header-middle-button" style={headerMiddleButton} onClick={() => OnLessonClick(level_id, unit_id)}>{localization.Lessons}</div> 
                        :
                        <div  className="header-middle-button header-disabled-button" onClick={() => OnLessonButtonClick()} style={headerMiddleButton}>{localization.Lessons}</div> 
                    }
                    </div>
                </Col>         
                <Col xs={12} lg={4} className="header-end">
                    <div className="header-end-btn">
                        { unit_id ?
                        <div  className="header-side-buttons" style={headerDisabledSideButtons} onClick={() => OnUnitClick(level_id, unit_id)}>{localization.Unit}-{unit_id} </div> 
                        :
                        <div className="header-side-buttons header-disabled-button" style={ headerDisabledSideButtons }>{localization.Units} </div> 
                        }
                    </div>
                </Col>
            </Row>}
        </div>
      )
}
 
export default Header
