import Dexie from 'dexie'
import eng from '../localization/eng';
import por from '../localization/por';
import spa from '../localization/spa';
import gre from '../localization/gre';
import chn from '../localization/chn';
import { getUserLessonProgress } from './ApiHelpers'
let DB;

const initDB = () => {
  const db = new Dexie("EllStudyApp");
  db.version(1).stores(
    {
      stateManagement: "state" ,
      users: "id, user_id" ,
      initialData: "user_id" ,
      studyLevels: "id, sort_order" ,
      studyUnits: "id, level_id, sort_order" ,
      studyLessons: "id, unit_id, sort_order" ,
      studyQuestions: "id, lesson_id, sort_order, is_send_to_server" ,
      studyResponses: "id, lesson_id, question_id" ,
    }
  ) 
  return db;
};

const getDB = () => {
  if (!DB) {
    DB = initDB();
  }

  const idb = DB.backendDB();

  if (idb) 
  {
    try {
      // Check if if connection to idb did not close in the meantime
      idb.transaction('stateManagement').abort();
    } 
    catch (e) 
    {
      DB.close();
      DB = initDB();
      console.log(e.message)
    }
  }

  return DB;
}
 

//Rest Last State
export const checkDatabase = async (state, message) => 
{
  try
  {
      const db        = getDB()
      const levels    = await db.studyLevels.count()
      const units     = await db.studyUnits.count()
      const lessons   = await db.studyLessons.count()
      const questions = await db.studyQuestions.count()
      const responses = await db.studyResponses.count()
      
      //console.log(levels, units, lessons, questions, responses)

      if(parseInt(levels) === 0 || parseInt(units) === 0 || parseInt(lessons) === 0 || parseInt(questions) === 0 || parseInt(responses) === 0)
        return false
      else
        return true

  } 
  catch (e) 
  {
    console.log('Reading State Failed' + e.message); // handle error
    return false
  }
}


//Rest Last State
export const readAppState = async (state, message) => 
{
  try
  {
      const db  = getDB()
      const appState  = await db.stateManagement.where({state: 'APP'}).toArray()
      
      if(appState && appState.length)
        return appState
      else
        return false

  } 
  catch (e) 
  {
    console.log('Reading State Failed' + e.message); // handle error
    return false
  }
}


// Save Initial Load Data
export const firstInit = async (param_user_id, language, studentName) => 
{
  try
  {
      const db  = getDB()

      // Always clear the local DB when doing an init so we can get fresh data
      await db.initialData.clear()
      await db.studyLevels.clear()
      await db.studyUnits.clear()
      await db.studyLessons.clear()
      await db.studyQuestions.clear()
      await db.studyResponses.clear()
      await db.users.clear()
      
      await db.users.put({'id': 1, 'user_id': parseInt(param_user_id), "language": language, "name": studentName})
      return true
  } 
  catch (e) 
  {
    console.log('Saving Init Failed' + e.message); // handle error
    return false
  }
}

export const getUserLanguage = async () => {
  const db = getDB();

  const user = await getUserId();
  const users  = await db.users.toArray();
  let lang = "eng";

  for (let i = 0; i < users.length; ++i) {
    if (parseInt(users[i].user_id) === parseInt(user)) {
      if ("language" in users[i]) {
        lang = users[i].language;
      }
    }
  }

  return lang;
}

export const getUserLocalization = async () => {
  let language = await getUserLanguage();
  
  if (language === "eng" || language === "English")
    return eng;
  else if (language === "spa" || language === "Español")
    return spa;
  else if (language === "por" || language === "Português")
    return por;
  else if (language === "chn" || language === "中文")
    return chn;
  else if (language === "gre" || language === "Ελληνικά")
    return gre;
  
  return eng;
}

// Get User ID
export const getUserId = async () => 
{
  try
  {
    const db  = getDB()
    const users  = await db.users.toArray()
    
    if(users && users.length)
      return users[0].user_id
    else
      return false     
  } 
  catch (e) 
  {
    console.log('Failed fetching User ID. ' + e.message); // handle error
    return false
  }
}


// Save Initial Load Data
export const saveAppState = async (state, message) => 
{
  try
  {
      const db  = getDB()
      await db.stateManagement.put({state:state, message: message})
      return true
  } 
  catch (e) 
  {
    console.log('Saving State Failed' + e.message); // handle error
    return false
  }
}


// Save Initial Load Data
export const saveInitialData = async (initialData) => 
{
  try
  {
      const db  = getDB()
      await db.initialData.put(initialData.initial_state)
      await db.studyLevels.bulkPut(initialData.levels)
      await db.studyUnits.bulkPut(initialData.units)
      await db.studyLessons.bulkPut(initialData.lessons)
      await db.studyQuestions.bulkPut(initialData.questions)
      await db.studyResponses.bulkPut(initialData.responses)
      
      let screen = 'lessons';
      if(initialData.initial_state.question_id > 1) {
        screen = 'questions';
      }

      const question_order  =   await getQuestionOrder(initialData.initial_state.question_id)  
      const sort_order      =   await getQuestionOrder(initialData.initial_state.sort_order)  
      const current_app_state = {'screen': screen, 'level': initialData.initial_state.level_id, 'unit': initialData.initial_state.unit_id, 'lesson': initialData.initial_state.lesson_id, 'question': question_order, 'sort_order': sort_order}
      await saveAppState('APP', current_app_state)

      //await saveAppState('success', 'Initial Data Saved')
     return true;
  } 
  catch (e) 
  {
    console.log('Failed fetching levels. ' + e.message); // handle error
    return false
  }
}

// Get Initial State
export const getInitialState = async () => 
{
  try
  {
    const db  = getDB()
    const initialState  = await db.initialData.toArray()
    
    if(initialState && initialState.length)
      return initialState
    else
      return false     
  } 
  catch (e) 
  {
    console.log('Failed fetching initial state. ' + e.message); // handle error
    return false
  }
}

export const checkIsAdmin = async () => 
{
  try
  {
    const db  = getDB()
    const initialState  = await db.initialData.toArray()

    if(initialState && initialState.length)
      return initialState[0].is_admin
    else
      return false     
  } 
  catch (e) 
  {
    console.log('Failed fetching initial state. ' + e.message); // handle error
    return false
  }
}

// Update Lesson Progress
export const updateLessonProgress = async (question_cp, user_id, unit) => 
{
  if (!question_cp.is_last_question)
    return false;
  
  try
  {
    const db  = getDB();

    // Get current last_finished_lesson_id
    const initial_data = await getInitialState();
    let last_finished_lesson_id = parseInt(initial_data[0].last_finished_lesson_id);

    if (parseInt(question_cp.lesson_id) > last_finished_lesson_id)
      last_finished_lesson_id = parseInt(question_cp.lesson_id);

    await db.initialData.update(parseInt(user_id), {last_finished_lesson_id: last_finished_lesson_id, unit_id: parseInt(unit)}).then(function (updated) {
      console.log(updated);
      if (!updated) {
        console.log ("Failed to update lesson progress")
        return false;
      }
    });
    
    return true;
  } 
  catch (e) 
  {
    console.log('Failed updating lesson progress', e.message); // handle error
    return false
  }
}
 

// Get Initial State
export const getQuestionOrder = async (question_id) => 
{
  try
  {
    const db  = getDB()
    const lesson  = await db.studyLessons.where({'id': question_id}).toArray()
    
    if(lesson && lesson.length)
      return lesson[0].sort_order
    else
      return false     
  } 
  catch (e) 
  {
    console.log('Failed fetching initial state. ' + e.message); // handle error
    return false
  }
}



//Rest Last State
export const readLessonStim = async (lesson_id, unit_id) => 
{
  try
  {
      const db  = getDB();

      let lessonInfo  = null;
      let lessons  = await db.studyLessons.where({unit_id: unit_id}).toArray();
      let found = false;
      
      if (lessons.length > 0) {
        for (let i = 0; i < lessons.length; ++i) {
          if (lessons[i].id === lesson_id) {
            lessons[i].sort_order = (i + 1);
            lessonInfo = lessons[i];
            found = true;
          }
        }
      }

      if(found) {
        return lessonInfo;
      }
        
      return false;
  } 
  catch (e) 
  {
    console.log('Reading Lesson info Failed' + e.message); // handle error
    return false
  }
}

export const getLessonSortOrder = async (lesson_id, unit_id) => 
{
  try
  {
      const db  = getDB();
      let sort_order  = 0;
      let lessons  = await db.studyLessons.where({unit_id: unit_id}).toArray();
      
      if (lessons.length > 0) {
        for (let i = 0; i < lessons.length; ++i) {
          if (lessons[i].id === lesson_id) {
            sort_order = (i + 1);
          }
        }
      }

      return sort_order;
  } 
  catch (e) 
  {
    console.log('Reading Lesson info Failed' + e.message); // handle error
    return false
  }
}

export const getLessonTitle = async (lesson_id, unit_id) => 
{
  try
  {
      const db = getDB();
      let title = "";
      let lessons = await db.studyLessons.where({unit_id: unit_id}).toArray();
      
      if (lessons.length > 0) {
        for (let i = 0; i < lessons.length; ++i) {
          if (lessons[i].id === lesson_id) {
            title = lessons[i].title;
            break;
          }
        }
      }

      return title;
  } 
  catch (e) 
  {
    console.log('Reading Lesson info Failed' + e.message); // handle error
    return false
  }
}

// Save Unit Lessons Data
export const saveUnitLessons = async (unitLessons) => 
{
  try
  {
    const db  = getDB()
    await db.studyLessons.bulkPut(unitLessons.lessons)
    await db.studyQuestions.bulkPut(unitLessons.questions)
    await db.studyResponses.bulkPut(unitLessons.responses)
    return true
  } 
  catch (e) 
  {
    console.log('Failed fetching levels failed. ' + e.message); // handle error
    return false
  }
}

// Update Question Score
export const updateQuestionScore = async (response) => 
{
  try
  {
    const db  = getDB()    
    await db.studyQuestions.put(response)
    return true
  } 
  catch (e) 
  {
    console.log('Failed updating score. ' + e.message); // handle error
    return false
  }
}


// Read Levels
export const getLevels = async () => 
{
  try
  {
    const db  = getDB()    
    const levels = await  db.studyLevels.toArray()   
    if(levels)
      return levels
    else
      return false

  } 
  catch (e) 
  {
    console.log('Failed reading levels . ' + e.message); // handle error
    return false
  }
}


// Read Units
export const getUnits = async (level_id) => 
{
  try
  {
    const db  = getDB()    
    const level_units_data  = await db.studyUnits.where({level_id: level_id}).toArray()
    if(level_units_data)
      return level_units_data
    else
      return false

  } 
  catch (e) 
  {
    console.log('Failed reading units . ' + e.message); // handle error
    return false
  }
}

//Read Lessons
export const getLessons = async (unit) => 
{
  try
  {
    const db  = getDB()    
    const lessons_data = await db.studyLessons.where({unit_id: unit}).toArray()
    if(lessons_data)
      return lessons_data
    else
      return false

  } 
  catch (e) 
  {
    console.log('Failed reading lessons . ' + e.message); // handle error
    return false
  }
}

//Read Lessons
export const getUnitIdByLesson = async (lesson_id) => 
{
  try
  {
    const db  = getDB()    
    const lessons_data = await db.studyLessons.where({id: lesson_id}).toArray()
    if(lessons_data)
      return parseInt(lessons_data[0].unit_id)
    else
      return 0

  } 
  catch (e) 
  {
    console.log('Failed reading unit id . ' + e.message); // handle error
    return 0
  }
}

//Read Lessons
export const getLevelIdByUnit = async (unit_id) => 
{
  try
  {
    const db  = getDB()        
    const level_units_data  = await db.studyUnits.where({id: unit_id}).toArray()
    if(level_units_data)
      return parseInt(level_units_data[0].level_id)
    else
      return 0

  } 
  catch (e) 
  {
    console.log('Failed reading level id . ' + e.message); // handle error
    return 0
  }
}

export const processReviewStats = async (lesson_id, user_id) => 
{
  var score_percentage = 0
  var score_text = 'GREAT WORK'
  var score_class = 'bg-green' 

  //Read Questions    
  const userLessonProgress = await getUserLessonProgress(user_id, lesson_id);

  let totalQuestions = 0;
  let correctQuestions = 0;
  let start_time = null;
  let end_time = null;
  let lastKey = null;

  for (const key in userLessonProgress.progress) {
    // Get the first questions start time
    if (totalQuestions === 0) {
      start_time = userLessonProgress.progress[key].response_datetime;
    }

    // Check to see if the user got the question correct
    if (userLessonProgress.progress[key].score === 1)
      correctQuestions += 1;

    totalQuestions += 1;
    lastKey = key;
  }

  if (lastKey !== null)
    end_time = userLessonProgress.progress[lastKey].response_datetime;

  if (parseInt(totalQuestions) > 0)
    score_percentage          =   (correctQuestions * 100) / totalQuestions
  else
    score_percentage          =   0

  if(score_percentage < 50)  
  {
    score_text = 'TRY HARDER'
    score_class = 'bg-danger'
  }  


  if(score_percentage >= 50)
  {
    score_text = 'VERY GOOD'
    score_class = 'bg-warning'
  }  


  if(score_percentage >= 75) 
  {
    score_text = 'GREAT WORK'
    score_class = 'bg-green'
  }

  let unit_id = await getUnitIdByLesson(lesson_id);
  let sortOrder = await getLessonSortOrder(lesson_id, unit_id);
  let lessonTitle = await getLessonTitle(lesson_id, unit_id);

  // Remove lesson id from titles since its in the database for now
  lessonTitle = lessonTitle.replace(lesson_id, "");

  const review_stats = {'title': lessonTitle, 'sort_order': sortOrder, 'totalQuestions': totalQuestions, 'correctQuestions': correctQuestions, 'start_time': start_time, 'end_time': end_time, 'score_text': score_text, 'score_class': score_class, 'score_percentage': score_percentage}
   
  return  review_stats;
}

