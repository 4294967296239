import { useHistory  } from 'react-router-dom'
import { useState, useEffect } from 'react'

//Components and Utilities
import { saveAppState, getLevels, getInitialState, getUserId}  from '../utils/DbHelpers';
import Header from './Header';

//Design Assets and Bootstrap
import {Container , Row, Col} from 'react-bootstrap';
import { FaTasks, FaLock } from 'react-icons/fa'
import '../levels.scss';

//Image Assets
import long_textholder from '../assets/long_textholder.png';
 

const levelStyle = 
{
    backgroundImage: `url(${long_textholder})`,
};
 
 
 
 
const Levels = (props) => 
{
  const history = useHistory()  
  const [levels, setLevels] = useState([]);    
  const [initialData, setInitialData] = useState([]);    
  const [errorMessage, setErrorMessage] = useState(null)

  useEffect(() => 
  {
    const readLevels = async () => 
    {
      
       //Fetch Levels from Db
       const levels_data = await getLevels()
       const user_id     = await getUserId(getUserId)

       if(levels_data)
       {
          const current_app_state = {'screen': 'levels', 'level': 0, 'unit': 0, 'lesson': 0, 'question': 0}
          await saveAppState('APP', current_app_state)
          
          const initial_data = await getInitialState()           
          for (let i = 0; i < initial_data.length; ++i) {
            if (parseInt(initial_data[i].user_id) === parseInt(user_id)) {
              setInitialData(initial_data[i]);
              break;
            }
          }
          setLevels(levels_data)
       }
       else
       {
         setErrorMessage("Sorry, System unable to read levels. Please try again later")         
       }
    }
    readLevels()
  }, [])
  
  const onLevelClicked = async (id) => 
  {
    history.push(`/levels/${id}/units`) 
  } 


  return (
    <Container style={{display: "flex", flexDirection: "column", justifyContent: "space-between"}}>
      
      <Row>
        <Col xs="12">
          {errorMessage ? <p className="text-danger">{errorMessage}</p> : '' }
        </Col>
      </Row>

      <Row>
        <Col xs="12">
          <Header level_id={""} unit_id={""}  lessons_id={""}/>
        </Col>
      </Row>

      <Row key="r2">
        {
          levels.map((level, index) => 
          {
            return (
                <Col key={`c${index}`}  xs={12} md={6}  xl={4} >
                  {
                  (parseInt(initialData.level_id) < parseInt(level.id)) ? 
                  <div className="default-list-button locked-item" style={levelStyle}>
                      <div className="engraved">  
                        <FaLock className="mb-1"></FaLock> 
                        <p className="px-2 d-inline-block"><strong>{level.title}</strong></p>
                      </div>
                  </div>
                  :
                  <div className="default-list-button" style={levelStyle}  onClick={() => onLevelClicked(level.id)}>
                      <div className="engraved">  
                        <FaTasks className="mb-1"></FaTasks> 
                        <p className="px-2 d-inline-block"><strong>{level.title}</strong></p>
                      </div>
                  </div>
                  }
              </Col> 

              )
          })
        }  
      </Row>      
    </Container>
  )
}
 
export default Levels
