import { useHistory  } from 'react-router-dom'
import { useState, useEffect,   useRef } from 'react'

import {Container , Row, Col, Button, Alert } from 'react-bootstrap';
import { FaArrowRight, FaArrowLeft } from 'react-icons/fa'
import '../levels.scss';

import { ASSETS_BASE_PATH  } from '../constants/constants';
import { getLessonS3Data }  from '../utils/ApiHelpers';
import { readLessonStim, getUserLocalization }  from '../utils/DbHelpers';
import Img from '../assets/play_audio.png';

const Phonics = (props) => 
{
  const history = useHistory()  

  const [errorMessage, setErrorMessage] = useState('');    
  const [stimData, setStimData] = useState([]);    
  const [stimImage, setStimImage] = useState();
  const [stimAudio, setStimAudio] = useState('');
  const [audioDone, setDone] = useState(false);

  const [audioPlaying, setAudioPlaying] = useState(false);
  const refImage = useRef(null)
  const [lessonAssetPath, setLessonAssetPath] = useState('');
  const [localization, setLocalization] = useState('');

  useEffect(() => 
  {
    const init = async () => 
    {
      const lesson_id     =   parseInt(props.match.params.lesson_id)

      if(parseInt(lesson_id) > 0)
      {
          const lessonInfo = await readLessonStim(lesson_id, parseInt(props.match.params.unit_id));
          let phonicInfo = await getLessonS3Data(ASSETS_BASE_PATH + `/level-${parseInt(props.match.params.level_id)}/unit-${parseInt(props.match.params.unit_id)}/lesson-${lessonInfo.sort_order}/audio/phonic.json`);
          setLessonAssetPath(ASSETS_BASE_PATH + `/level-${parseInt(props.match.params.level_id)}/unit-${parseInt(props.match.params.unit_id)}/lesson-${lessonInfo.sort_order}/`);

          let local = await getUserLocalization();
          setLocalization(local);

          const phonics_data = phonicInfo;
          const stim_images = phonics_data.images;
          const stim_audio = phonics_data.audiosrc;
          const stim_image = phonics_data.coverimg;

          if(stim_images.length > 0) 
          {
            setStimImage(stim_image)
            setStimData(phonics_data)
            setStimAudio(stim_audio)
          }
          else {
            const parm_level_id =   parseInt(props.match.params.level_id)
            const unit          =   parseInt(props.match.params.unit_id)

            history.push(`/levels/${parm_level_id}/units/${unit}/lessons/${lesson_id}/questions/1`)
            return
          }
      }        
      else
        setErrorMessage("Invalid Lesson. Please select valid lesson")
    }
    init()
  }, [history, props])
  
  const onSkipClicked = async () => 
  {
    if(audioPlaying === true)
    {
      let audio = document.getElementById("stim-audio");
      audio.pause();
    }
    
    const parm_level_id =   parseInt(props.match.params.level_id)
    const unit          =   parseInt(props.match.params.unit_id)
    const lesson_id     =   parseInt(props.match.params.lesson_id)
    
    history.push(`/levels/${parm_level_id}/units/${unit}/lessons/${lesson_id}/questions/1`)
  }
  
  function onPlayStopped()
  {
    if (audioPlaying) {
      setAudioPlaying(false)
      let audio = document.getElementById("stim-audio");
      audio.pause();
      
      clearTimeout(window.timeout)
      setStimImage(stimData.coverimg)
    }
  }

  function onPlayClicked () 
  {
    setAudioPlaying(true);
    let audio = document.getElementById("stim-audio");
    audio.play();
  }

  function onBackClicked() {
    const parm_level_id =   parseInt(props.match.params.level_id);
    const unit          =   parseInt(props.match.params.unit_id);
    const lesson_id     =   parseInt(props.match.params.lesson_id);
    
    history.push(`/levels/${parm_level_id}/units/${unit}/lessons/${lesson_id}/welcome`);
    return;
  }

  const onAudioUpdate = () => {
    let audio = document.getElementById("stim-audio");
    let currentTime = parseFloat(audio.currentTime);
    let currentIndex = 0;

    for (let i = 0; i < stimData.images.length; ++i) {
      if (currentTime >= parseFloat(stimData.images[i].time)) {
        currentIndex = i;
      }
    }

    setStimImage(stimData.images[currentIndex].image);
  }

  const onAudioEnd = () => {
    setAudioPlaying(false);
    setDone(true);
  }
  
  if(!stimImage)
    return null;

//   const RawHTML = ({children, className = ""}) => 
// < div className={className}
//   dangerouslySetInnerHTML={{ __html: children.replace(/\n/g, '<br />')}} />

  return (
    <Container style={{display: "flex", flexDirection: "column", justifyContent: "space-between"}}>
      {errorMessage &&
        <Row key="r0"> 
            <Col key="r0-c1">
                <Alert variant='danger'>
                    {errorMessage}
                </Alert>
            </Col>  
        </Row>
      }
      {stimData && 
      <Row key="r1" className="video-container">
        <audio id="stim-audio" onTimeUpdate={onAudioUpdate}  onEnded={onAudioEnd}>
          <source src={lessonAssetPath + `audio/${stimAudio}.mp3`} type="audio/mp3" />
        </audio>
        <Col xs={12} lg={9}  key="r1-c1" style={{padding:"0px"}}>
           <div ref={refImage} className="lesson-welcome-images" onClick={onPlayStopped}>
              <img src={`${lessonAssetPath}images/${stimImage}`} alt="Intro" style={{width: "100%", height: "100%" }} />
              {!audioPlaying && 
                <button className="play-btn" onClick={onPlayClicked}>
                  <img className="play-btn-img" src={Img} alt="#" />
                </button>}
           </div>
        </Col>
        {/* <Col xs={12} lg={6}  key="r1-c2" style={{padding:"0px"}}>
           <div className="lesson-welcome-script" style={{minHeight: imageHeight, objectFit: "contain"}}>
              {audioPlaying &&
                <RawHTML>{stimScript}</RawHTML>
              }
              {!audioPlaying &&
                <img src={StimCover} alt="Intro" style={{width: "100%" }} />
              }
           </div>
        </Col> */}
      </Row>
      }    
      <Row key="r2" className="nav-container-footer">
        <Col xs={6} md={6} key="r2-c22" className="skip-btn-container" style={{textAlign:"center"}}>
          <Button
            variant="warning"            
            onClick={onBackClicked}
            className="lesson-stim-button"
            >
              <>
                <FaArrowLeft></FaArrowLeft> {localization.Back} 
              </>
          </Button>
         </Col>
         <Col xs={6} md={6} key="r2-c21" className="skip-btn-container" style={{textAlign:"center"}}>
          <Button
            variant="warning"            
            onClick={onSkipClicked}
            className="lesson-stim-button"
            >
              {audioDone &&
                <>
                  {localization.Next} <FaArrowRight className={"arrow-bounce"}></FaArrowRight>
                </>
              }
              {!audioDone &&
                <>
                  {localization.Skip} <FaArrowRight></FaArrowRight>
                </>
              }
          </Button>
         </Col>
      </Row>    
    </Container>
  )
}
 
export default Phonics
