import { useHistory  } from 'react-router-dom'
import { useState, useEffect } from 'react'

import {Container , Row, Col} from 'react-bootstrap';
import '../levels.scss';
import { saveAppState, getLessons, getInitialState, getUserId, getUnitIdByLesson}  from '../utils/DbHelpers';
import { FaLock } from 'react-icons/fa'
import Header from './Header';
import ReviewScreen from './ReviewScreen';
 
//Image Assets
import reward from '../assets/reward.png';
import Swal from 'sweetalert2';

const Lessons = (props) => 
{
   
  const history = useHistory()
  const [level, setLevel] = useState([]);    
  const [unit, setUnit] = useState([]);      
  const [lessons, setLessons] = useState([]);    
  const [initialData, setInitialData] = useState([]);    
  const [errorMessage, setErrorMessage] = useState(null);
  const [showReview, setShowReview] = useState(false);
  const [lesson, setLesson] = useState();

  useEffect(() => 
  {
    let isMounted = true;
    setShowReview(false);

    const readLessons = async () => 
    {
      const parm_level_id   =   parseInt(props.match.params.level_id)
      const unit            =   parseInt(props.match.params.unit_id)
      const queryString = window.location.search;
      const urlParams = new URLSearchParams(queryString);

      const lessons_data    =   await getLessons(unit)
      const user_id         = await getUserId(getUserId)

      if(lessons_data)
      {
        if (!isMounted)
          return;
        
        const current_app_state = {'screen': 'lessons', 'level': parm_level_id, 'unit': unit, 'lesson': 0, 'question': 0}
        await saveAppState('APP', current_app_state)
        
        const initial_data = await getInitialState()  
        let lessonId = 0;

        for (let i = 0; i < initial_data.length; ++i) {
          if (parseInt(initial_data[i].user_id) === parseInt(user_id)) {
            setInitialData(initial_data[i]);
            lessonId = initial_data[i].last_finished_lesson_id
            break;
          }
        }

        if (urlParams.get("review") !== null) {
          let lessonToLoad = lessonId;
          
          if (urlParams.get("lesson") !== null) {
            lessonToLoad = parseInt(urlParams.get("lesson"));
          }

          if (urlParams.get("finished_unit") !== null) {
            const currentUnitID = await getUnitIdByLesson((lessonId));
            if (currentUnitID === unit) {
              const nextUnitID = await getUnitIdByLesson((lessonId + 1));
              
              if (nextUnitID > unit) {
                Swal.fire({
                  title: 'Success!',
                  text: "You've completed all the lessons in the unit!",
                  icon: 'success',
                  confirmButtonText: 'Continue'
                })
              }
            }
          }

          setLesson(lessonToLoad);
          setShowReview(true);
        }

        setUnit(unit)      
        setLevel(parm_level_id)      
        setLessons(lessons_data)
      }
      else
      {
        setErrorMessage("Sorry, System unable to read lessons. Please try again later")         
      }
    }
    readLessons();

    return () => {
      isMounted = false;
    };
  },  [props.location, props])
  
  const onLessonClick = async (id) => 
  {
    if (parseInt(initialData.last_finished_lesson_id) >= parseInt(id))
    {
      setLesson(id);
      setShowReview(true);
    }
    else {
      history.push(`/levels/${level}/units/${unit}/lessons/${id}/welcome`) ;
    }
  }

  const onCloseReview = async() =>
  {
    setShowReview(false);
  }

  if(!lessons)
  {
    return null
  }

  return (
    <>
      {showReview &&
      <ReviewScreen key={'review-screen-container'} lesson={lesson} onCloseReview={onCloseReview}></ReviewScreen>}
      <Container key={'review-container'} className="lessons-page" style={{display: "flex", flexDirection: "column", justifyContent: "space-between"}}>
        <Row>
          <Col xs="12">
            {errorMessage ? <p className="text-danger">{errorMessage}</p> : '' }
          </Col>
        </Row>

        <Row>
          <Col xs="12">
            <Header level_id={level} unit_id={unit}  lessons_id={""}/>  
          </Col>
        </Row>
        <Row key="r2">
          <Col className="text-center" xs={12}>
            <div className="learning-path-container">
              <div className={"learning-path-png img-fluid unit-" + unit}></div>
                {
                  lessons.map((lesson, index) => ( 
                  [
                    (parseInt(initialData.last_finished_lesson_id) + 1 < parseInt(lesson.id)) ? 
                  
                    <div key={`les${index}`}  className={`locked-item lesson-btn lesson-${index+1}`}  >
                    
                      <img src={reward} className="lessonReward" alt="unavailable-lesson"/>
                      <div className="lessonLockBackground"></div>
                      <FaLock className="lessonLockSign" style={{'display': 'block'}}></FaLock> 
                      
                    </div>   
                    :
                    <div key={`les${index}`}  className={`lesson-btn lesson-${index+1}`}   onClick={() => onLessonClick(lesson.id)}>
                      {
                        (parseInt(initialData.last_finished_lesson_id) + 1 === parseInt(lesson.id)) ? 
                        <div className="lesson-btn-outer"></div>
                        :
                        ""
                      }
                      
                      <img src={reward} className="lessonReward" alt="available-lesson"/>
                    </div>   
                  ]
                  ))
                }
            </div>
          </Col>
        </Row>
      </Container>
    </>
  )
}
 
export default Lessons
