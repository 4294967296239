import { ASSETS_BASE_PATH, NET_CONNECTIVITY_TEST_URL } from '../constants/constants';

 
//Helper Function to slugify any string
export function slugify(str) {

	if(str){
    	str = str.toLowerCase().replace(/ /g, '-').replace(/:/g,'-').replace(/&/g,'-').replace(/--/g,'-');
    	str = str.replace(/--/g,'-');
    	return str;
	}else{
		return str;
	}
}

//Helper Function for Asset Path
export function assetPath(asset) 
{

	if(asset)
	{
    	let asset_path = ASSETS_BASE_PATH + "/" + asset
    	return  asset_path;
	}
	else
	{
		return '';
	}
}

window.addEventListener('online', handleConnection);
window.addEventListener('offline', handleConnection);

function handleConnection() {
  if (navigator.onLine) 
  {
	isApiSuccess(NET_CONNECTIVITY_TEST_URL); 
	isReachable(NET_CONNECTIVITY_TEST_URL).then(function(response) 
	{
      if (response) 
	  {
        if(response.connection === 'success')		
        	console.log('online');
		else
			console.log('no connectivity');
      } else {
        console.log('no connectivity');
      }
    });
  } else {
    // handle offline status
    console.log('offline');
  }
}

//Helper function to if any URL is reachable. To Test Net Connection
function isReachable(url) 
{
  	return fetch(url, { method: 'HEAD', mode: 'no-cors' })
	.then(function(resp) 
	{
		console.log(resp)
		return resp && (resp.ok || resp.type === 'opaque');
	})
	.catch(function(err) {
		console.warn('[conn test failure]:', err);
	});
}

//Helper function to check if API is working.
function isApiSuccess(url) 
{
	url = url + "?time=" + Date.now();
	return fetch(url)
	.then((response) => response.json())
	.then((result) => {
		console.log(result);
		return result && (result.connection === "success");
	})
	.catch((error) => {
		return false;
	});
}

export function getMinutesBetweenDates(startDate, endDate, localization) 
{
	var startTime = new Date(startDate); 
	var endTime = new Date(endDate);

	// In case its IOS, parse the fulldate parts and re-create the date object.
	if(Number.isNaN(startTime.getMonth())) {
		let arr = startDate.split(/[- :]/);
		startTime = new Date(arr[0], arr[1]-1, arr[2], arr[3], arr[4], arr[5]);
		
		arr = endDate.split(/[- :]/);
		endTime = new Date(arr[0], arr[1]-1, arr[2], arr[3], arr[4], arr[5]);
	}

	var difference = (endTime - startTime) / 1000;

	var minutes = 0
	var seconds = 0
	var hours = 0
	var days = 0
	var time_left = 0

	if(difference < 60)
		return difference + " " + localization.Seconds

	if(difference > 60 && difference < 3600)
	{
		minutes = Math.floor(difference / 60)		
		seconds = difference - minutes * 60;
		if(seconds > 0)
		{
			return minutes + " " + localization.Min + " " + seconds + " " + localization.Sec;
		}
		else
		{
			if(parseInt(minutes) >= 2)
				return minutes + " " + localization.Minutes
			else
				return minutes + " " + localization.Minute
		}
	}	

	if(difference >= 3600)
	{
		hours = Math.floor(difference / 3600)		
		time_left = difference - hours * 3600
		minutes = Math.floor(time_left / 60)

		if(minutes > 0)
		{
			return hours + " " + localization.Hour + " " +  minutes + " " + localization.Min
		}
		else
		{
			if(parseInt(hours) >= 2)
				return hours + " " + localization.Hours
			else
				return hours + " " + localization.Hour
		}
	}

	if(difference >= 86400)
	{
		days = Math.floor(difference / 86400)		
		time_left = difference - days * 86400
		hours = Math.floor(time_left / 3600)

		if(hours > 0)
		{
			return days + " " + localization.Day + " " + hours + " " + localization.Hour
		}
		else
		{
			if(parseInt(days) >= 2)
				return days + " " + localization.Days
			else
				return days + " " + localization.Day
		}
	}
}


 

