import '../levels.scss';
import answers_meter_placeholder from '../assets/answers_meter_placeholder.png';
import tick from '../assets/tick.png';
import cross from '../assets/cross.png';

const answerMeterStyle = 
{
    backgroundImage: `url(${answers_meter_placeholder})`,
};

const MAX_QUESTIONS = 10;

const AnswersMeter = ({lessonQuestions, currentQuestion=0}) => 
{
    let currentQuestionSet = lessonQuestions;
    let indexModifier = 0;
    /*
        This logic will split a group of questions higher than MAX_QUESTIONS into
        two seperate groups so the AnswerMeter can fit them all properly.
    */
    if (lessonQuestions.length >= MAX_QUESTIONS) {
        if (currentQuestion >= MAX_QUESTIONS) {
            currentQuestionSet = lessonQuestions.slice(MAX_QUESTIONS, lessonQuestions.length);
            indexModifier = MAX_QUESTIONS;
        }
        else {
            currentQuestionSet = lessonQuestions.slice(0, MAX_QUESTIONS);
        }
    }

    return (
        <div className="answers-meter-blocks" style={answerMeterStyle}>
            {currentQuestionSet.map((question, index) => 
                {
                    return (
                        <div className={"answer-boxes " + ((index + indexModifier) === currentQuestion ? 'selectedBorder' : null) } key={`am${index}`}>
                            { question.score === 1 && <img src={tick} className="crossTick" alt="Correct"/> }
                            { question.score === -1 &&  <img src={cross} className="crossTick" alt="Wrong"/> }
                        </div> 
                    )
                })
            }   
        </div>
    )
}

export default AnswersMeter;
