import { useHistory  } from 'react-router-dom'
import { useState, useEffect } from 'react'

import {Container , Row, Col} from 'react-bootstrap';
import { FaBookOpen, FaLock } from 'react-icons/fa'
import '../levels.scss';

import Header from './Header';
import long_textholder from '../assets/long_textholder.png';
import { saveAppState, getUnits, getInitialState, getUserId, getUnitIdByLesson }  from '../utils/DbHelpers';
 
const buttonStyle = 
{
    backgroundImage: `url(${long_textholder})`,
    
};
 
const Units = (props) => 
{
   
  const history = useHistory()
  const [level, setLevel] = useState([]);    
  const [units, setUnits] = useState([]);
  const [errorMessage, setErrorMessage] = useState(null)
  const [currentUnit, setCurrentUnit] = useState(0)

  useEffect(() => 
  {
    
    const readUnits = async () => 
    {
      setCurrentUnit(1);
      const parm_level_id     = parseInt(props.match.params.level_id)
      const level_units_data  = await getUnits(parm_level_id)
      const user_id         = await getUserId(getUserId)

      if(level_units_data)
      {
        const current_app_state = {'screen': 'units', 'level': parm_level_id, 'unit': 0, 'lesson': 0, 'question': 0}
        await saveAppState('APP', current_app_state)

        let initial_data = await getInitialState()

        for (let i = 0; i < initial_data.length; ++i) {
          if (parseInt(initial_data[i].user_id) === parseInt(user_id)) {
            initial_data = initial_data[i];
            break;
          }
        }

        setLevel(parm_level_id)      
        setUnits(level_units_data)

        let initialUnit = parseInt(initial_data.unit_id);

        // Check users last finished lesson to see if we need to unlock the next Unit
        const unitId = await getUnitIdByLesson((initial_data.last_finished_lesson_id + 1));

        if (unitId >= initialUnit) {
          setCurrentUnit(unitId);
        }
        else if (!isNaN(initialUnit)) {
          setCurrentUnit(initialUnit);
        }
        else {
          setCurrentUnit(1);
        }
      }
      else
      {
        setErrorMessage("Sorry, System unable to read units. Please try again later")         
      }
     
    }
    readUnits()

  }, [props])
  
  const onUnitClick = async (id) => 
  {
      history.push(`/levels/${level}/units/${id}/lessons`) 
  } 
 
  return (
    <Container  style={{display: "flex", flexDirection: "column", justifyContent: "space-between"}}>      
    
    <Row>
      <Col xs="12">
        {errorMessage ? <p className="text-danger">{errorMessage}</p> : '' }
      </Col>
    </Row>

    <Row>
      <Col xs="12">
          <Header level_id={level} unit_id={""}  lessons_id={""}/>
      </Col>
    </Row>
    <Row key="r2">
        {units.map((unit, index) => (
            <Col key={`unit${index}`}   xs={12} md={6}  xl={4} >
                {
                  (currentUnit < parseInt(unit.id)) ? 
                  <div className="default-list-button locked-item" style={buttonStyle}>
                      <div className="engraved">  
                        <FaLock className="mb-1"></FaLock> 
                        <p className="px-2 d-inline-block"><strong>{unit.title}</strong></p>
                      </div>
                  </div>
                  :

                <div   className="default-list-button" style={buttonStyle}  onClick={() => onUnitClick(unit.id)}>
                  <div className="engraved">  
                    <FaBookOpen className="mb-1"></FaBookOpen> 
                    <p className="px-2 d-inline-block"><strong>{unit.title}</strong></p>
                  </div>
                </div>
                }
            </Col>   
         ))}
          </Row>
    </Container>
  )
}
 
export default Units
