import React, { useState, useRef, useEffect, useCallback } from 'react'
import { useSpring, animated } from "react-spring";
import styled from 'styled-components'
import { MdClose } from 'react-icons/md'
import PopUpPreview from './PopUpPreview';
import { updateQuestions } from '../utils/ApiHelpers';

const Background = styled.div`
    margin:0;
    padding: 0;
    width: 100%;
    height: 100vh;
    background: rgba(0,0,0, 0.6);
    position: absolute;
    left: 0;
    top: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 5;
    margin: auto;
`
const ModalWrapper = styled.div`
    width: 800px;
    max-height: 700px;
    box-shadow: 0 5px 16px rgba(0,0,0, 0.2);
    background: #fff;
    color: #000;
    display: grid;
    grid-template-columns: 1fr;
    position: relative;
    z-index: 10px;
    border-radius: 10px;
    overflow: scroll;
    padding-bottom: 25px;
`

const CloseModalButton = styled(MdClose)`
    cursor: pointer;
    position: absolute;
    top: 15px;
    right: 20px;
    width: 32px;
    height: 32px;
    padding: 0;
    z-index: 10;
`

const PopUpModal = ({ showModal, setShowModal, question, responses, lessonSort }) => {
    const modalRef = useRef()
    const [preview, setPreview] = useState(false)
    const [stateindex, setstateindex] = useState(0)
    const [buttonDisable, setbuttonDisable] = useState(true)

    // Load images
    const [img1, setImg1] = useState(null)
    const [img2, setImg2] = useState(null)
    // Load audios
    const [audio1, setAudio1] = useState(null)
    const [audio2, setAudio2] = useState(null)
    // Set Text Values
    const [text1, setText1] = useState(null)
    const [text2, setText2] = useState(null)
    const [qesText, setQesText] = useState(null)

    const animation = useSpring({
        config: {
          duration: 250
        },
        opacity: showModal ? 1 : 0,
        transform: showModal ? `translateY(0%)` : `translateY(-100%)`
      });

    const setValuesToNull = () => {
        setImg1(null)
        setImg2(null)
        setAudio1(null)
        setAudio2(null)
        setText1(null)
        setText2(null)
        setQesText(null)
        setbuttonDisable(true)
    }

    const closeModal = e => {
        if (modalRef.current === e.target) {
            setPreview(false)
            setShowModal(false)
            setValuesToNull()
        }
    }

    const buttonClose = () => {
        setPreview(false)
        setShowModal(false)
        setValuesToNull()
    }

    const keyPress = useCallback(e => {
        if (e.key === 'Escape' && showModal) {
            setPreview(false)
            setShowModal(false)
            setValuesToNull()
        }
    }, [setShowModal, showModal])

    useEffect(() => {
        document.addEventListener('keydown', keyPress)
        return () => document.removeEventListener('keydown', keyPress)
    }, [keyPress])

    const formSubmit = (e) => {
        e.preventDefault()
        const data = new FormData()

        data.append('img1', img1)
        data.append('img2', img2)
        data.append('audio1', audio1)
        data.append('audio2', audio2)
        data.append('text1', text1)
        data.append('text2', text2)
        data.append('qesText', qesText)
        data.append('questionType', question.question_type)
        data.append('question_id', question.id)
        data.append('question_content', question.question_content)
        data.append('level_id', question.level_id)
        data.append('unit_id', question.unit_id)
        data.append('lesson_id', question.lesson_id)
        data.append('lesson_sort_order', lessonSort)
        data.append('response1', responses[0].id)
        data.append('response2', responses[1].id)
        data.append('response1_content', responses[0].response_content)
        data.append('response2_content', responses[1].response_content)

        if (responses.length === 3) {
            data.append('response3', responses[2].id)
            data.append('response3_content', responses[2].response_content)
        }

        updateQuestions(data)
        setShowModal(false)
        setValuesToNull()
    }

    const imageHandler = (e, index) => {
        const reader = new FileReader()
        reader.onload = () => {
            if (reader.readyState === 2) {
                if (index === 0) {
                    setImg1(reader.result)
                }else{
                    setImg2(reader.result)
                }
            }
        }
        setbuttonDisable(false)
        reader.readAsDataURL(e.target.files[0])
    }

    const audioHandler = (e, index) => {
        const reader = new FileReader()
        reader.onload = () => {
            if (reader.readyState === 2) {
                if (index === 0) {
                    setAudio1(reader.result)
                }else{
                    setAudio2(reader.result)
                }
            }
        }
        setbuttonDisable(false)
        reader.readAsDataURL(e.target.files[0])
    }
    
    const textHandler = (e, index) => {
        if (index === 1) {
            setText1(e.target.value)
        }else{
            setText2(e.target.value)
        }
        setbuttonDisable(false)
    }
    
    const quesTextHandler = (e, index) => {
        setbuttonDisable(false)
        setQesText(e.target.value)
    }

    return (
        <>
            {showModal ? (
                <Background ref={modalRef} onClick={closeModal}>
                    <animated.div style={animation}>
                        <ModalWrapper showModal={showModal}>
                            <form onSubmit={formSubmit} style={{width: '95%', margin: 'auto'}}>
                                {(question.question_type === 'IMAGE-AUDIO' || question.question_type === 'IMAGE-TEXT' || question.question_type === 'TEXT-AUDIO' || question.question_type === 'TEXT-TEXT') && 
                                    <>
                                        <div className="row">
                                            <div className="col-md-12 form-group mt-4">
                                                {(question.question_type === 'IMAGE-AUDIO' || question.question_type === 'IMAGE-TEXT') &&
                                                    <>
                                                        <label>
                                                            Question Text:
                                                        </label>
                                                        <input type='text' placeholder="Question Text" className="form-control" onChange={(e) => quesTextHandler(e, stateindex)} />   
                                                        <label>
                                                            Choose Image:
                                                        </label>
                                                        <input type='file' className="form-control" onChange={(e) => imageHandler(e, stateindex)} />   
                                                    </>
                                                }
                                                {(question.question_type === 'TEXT-AUDIO' || question.question_type === 'TEXT-TEXT') &&
                                                    <>
                                                        <label>
                                                            Question Text:
                                                        </label>
                                                        <input type='text' placeholder="Question Text" className="form-control" onChange={(e) => quesTextHandler(e, stateindex)} />
                                                    </>
                                                }
                                            </div>
                                        </div>
                                        <div className="row">
                                            {responses.map((res, index) => (
                                                <>
                                                    {(res.response_type === 'AUDIO') &&
                                                        <div key={index} className="col-md-6 form-group mt-2">
                                                            <label>
                                                                Choose Audio {index + 1}:
                                                            </label>
                                                            <input type='file' className="form-control" onChange={(e) => audioHandler(e, index)} />
                                                        </div>
                                                    }
                                                    {(res.response_type === 'TEXT') &&
                                                        <div key={index} className="col-md-6 form-group mt-2">
                                                            <label>
                                                                Response {index + 1}:
                                                            </label>
                                                            <input type='text' placeholder="Response text" className="form-control" onChange={(e) => textHandler(e, index)} />
                                                        </div>
                                                    }
                                                </>
                                            ))}
                                        </div>
                                    </>
                                }
                                {(question.question_type === 'AUDIO-IMAGE' || question.question_type === 'TEXT-IMAGES' || question.question_type === 'AUDIO-TEXT') &&
                                    <>
                                        <div className="row">
                                            <div className="col-md-12 form-group mt-4">
                                                {(question.question_type === 'AUDIO-IMAGE' || question.question_type === 'IMAGE-TEXT' || question.question_type === 'AUDIO-TEXT') &&
                                                    <>
                                                        <label>
                                                            Choose Audio:
                                                        </label>
                                                        <input type='file' className="form-control" onChange={(e) => audioHandler(e, stateindex)} />  
                                                    </>
                                                }
                                                {(question.question_type === 'TEXT-IMAGES') &&
                                                    <>
                                                        <label>
                                                            Question Text:
                                                        </label>
                                                        <input type='text' placeholder="Question Text" className="form-control" onChange={(e) => quesTextHandler(e, stateindex)} />
                                                    </>
                                                }
                                            </div>
                                        </div>
                                        <div className="row">
                                            {responses.map((res, index) => (
                                                <>
                                                    {(res.response_type === "IMAGE") &&
                                                        <div className="col-md-6 form-group mt-2">
                                                            <label>
                                                                Choose Image {index + 1}:
                                                            </label>
                                                            <input type='file' className="form-control" onChange={(e) => imageHandler(e, index)} />
                                                        </div>
                                                    }
                                                    {(res.response_type === "TEXT") &&
                                                        <div className="col-md-6 form-group mt-2">
                                                            <label>
                                                                Response {index + 1}:
                                                            </label>
                                                            <input type='text' placeholder="Response Text" className="form-control" onChange={(e) => textHandler(e, index)} />
                                                        </div>
                                                    }
                                                </>
                                            ))}
                                        </div>
                                    </>
                                }
                                <div className="row">
                                    <div className="col-md-12 mt-3">
                                    <button type="button" disabled={buttonDisable} className="btn btn-sm btn-primary" onClick={() => setPreview(prev => !prev)}>
                                        Preview
                                    </button>
                                    <div className="btn-group" role="group" aria-label="Basic example" style={{float: 'right'}}>
                                        <input type="submit" disabled={buttonDisable} className="btn btn-success" value="Submit" />
                                        <button type="button" disabled={buttonDisable} className="btn btn-danger" onClick={() => buttonClose()}>Cancel</button>
                                    </div>
                                    </div>
                                </div>
                            </form>

                            {(preview === true) && 
                                <PopUpPreview question={question} responses={responses} img1={img1} img2={img2} audio1={audio1} audio2={audio2} text1={text1} text2={text2} qesText={qesText} />
                            }

                            <CloseModalButton aria-label="Close modal" onClick={() => buttonClose()} />
                        </ModalWrapper>
                    </animated.div>
                </Background>
            ) : null }
        </>
    )
}

export default PopUpModal
