import React from 'react';
import { Switch, withRouter, BrowserRouter, Route } from 'react-router-dom'
import { useEffect } from 'react'

//Import CSS Files
import './App.css';
import './normalize.css';

//Import Components
import Levels from './components/Levels';
import Units from './components/Units';
import Lessons from './components/Lessons';
import LessonWelcomeScreen from './components/LessonWelcomeScreen';
import Phonics from './components/Phonics';
import Chant from './components/Chant';
import Question from './components/Question';
import LoadingButton from './components/Loading';
import InitApp from './components/Init';
import { useClearCache } from 'react-clear-cache';
 
const App = () =>
{
  useEffect(() => 
  {
    const appWarmUp = async () => 
    {
        //Any Code needed to run at startup      
    }
    appWarmUp()
  }, [])
 
  
  const AnimatedSwitch = withRouter(({ location }) => (
    
        <div>
        <Switch  location={location}>
        <Route
            path= '/init/:user_id'
            exact
            render={(props) => (
              <>
                
                <InitApp {...props}>
                  Start
                </InitApp >
              </>
            )}
          />     
          <Route
            path= '/'
            exact
            render={(props) => (
                <LoadingButton>
                </LoadingButton>
            )}
          />
          <Route
            path= '/landing'
            exact
            render={(props) => (
                <LoadingButton>
                </LoadingButton >
            )}
          />
          <Route
            path='/levels'
            exact
            render={(props) => (
              <>
                <Levels>                 
                </Levels>
              </>
            )}
          />

          <Route
            path='/levels/:level_id/units'
            exact
            render={(props) => (
              <>
                <Units {...props} >                 
                </Units>
              </>
            )}
          />

          <Route
            path='/levels/:level_id/units/:unit_id/lessons'
            exact
            render={(props) => (
              <>
                <Lessons {...props} >                 
                </Lessons>
              </>
            )}
          />

          <Route
            path='/levels/:level_id/units/:unit_id/lessons/:lesson_id/welcome'
            exact
            render={(props) => (
              <>
                <LessonWelcomeScreen {...props} >                 
                </LessonWelcomeScreen>
              </>
            )}
          />

          <Route
            path='/levels/:level_id/units/:unit_id/lessons/:lesson_id/phonics'
            exact
            render={(props) => (
              <>
                <Phonics {...props} >                 
                </Phonics>
              </>
            )}
          />

          <Route
            path='/levels/:level_id/units/:unit_id/lessons/:lesson_id/chant'
            exact
            render={(props) => (
              <>
                <Chant {...props} >                 
                </Chant>
              </>
            )}
          />

          <Route
            path='/levels/:level_id/units/:unit_id/lessons/:lesson_id/questions/:question_order_no'
            
            render={(props) => (
              <>
                <Question {...props} >                 
                </Question>
              </>
            )}
          />
        </Switch>
        </div> 
     
  ));

  window.localStorage.setItem("APP_VERSION", "1.5");
  const { isLatestVersion, emptyCacheStorage } = useClearCache();
  
  return (
    <div className="App">
        <BrowserRouter>
          <AnimatedSwitch />
        </BrowserRouter>
          {isLatestVersion && (
              <a
                href="/#"
                className="bottom-left version"
                onClick={e => {
                  e.preventDefault();
                  emptyCacheStorage();
                }}
              >
                ____
              </a>
          )}
    </div>  
  );
}

export default App;
