import { useHistory  } from 'react-router-dom'
import { useState, useEffect,   useRef } from 'react'

import {Container , Row, Col, Button, Alert } from 'react-bootstrap';
import { FaArrowRight , FaHome} from 'react-icons/fa'
import '../levels.scss';

import { readLessonStim, getUnitIdByLesson, getUserLocalization}  from '../utils/DbHelpers';
import { getLessonS3Data, getSubtitlesS3Data }  from '../utils/ApiHelpers';
import { ASSETS_BASE_PATH } from '../constants/constants';

import Img from '../assets/play_audio.png';
import StimCover from '../assets/watch.png';

const LessonWelcomeScreen = (props) => 
{
  const history = useHistory()  

  const [errorMessage, setErrorMessage] = useState('');    
  const [stimData, setStimData] = useState([]);    
  const [stimImage, setStimImage] = useState();
  const [stimScript, setStimScript] = useState('');
  const [stimAudio, setStimAudio] = useState('');
  const [videoDone, setDone] = useState(false);

  const [nextURL, setNextUrl] = useState('');
  const [backURL, setBackUrl] = useState('');
  
  const [audioPlaying, setAudioPlaying] = useState(false);
  const [imageHeight, setImageHeight] = useState(0);
  const refImage = useRef(null);

  const [animationStimType, setStimAnimationType] = useState(true);
  const [stimAnimation, setStimAnimation] = useState('');
  const [lessonAssetPath, setLessonAssetPath] = useState('');
  const [localization, setLocalization] = useState('');
  const [subtitles, setSubtitles] = useState(false);

  useEffect(() => 
  {
    let isMounted = true;

    const setWelcome = async () => 
    {
      if (!isMounted)
        return;

      const lesson_id     = parseInt(props.match.params.lesson_id)
      const unit_id       = parseInt(props.match.params.unit_id)
      const level_id       = parseInt(props.match.params.level_id)
      const correct_unit_id          =   await getUnitIdByLesson(lesson_id)
      
      let local = await getUserLocalization();
      setLocalization(local);

      // Make sure the lesson is in the proper unit, otherwise redirect to correct unit
      if (correct_unit_id !== unit_id) {
        history.push(`/levels/${level_id}/units/${correct_unit_id}/lessons/${lesson_id}/welcome`)
      }

      if(parseInt(lesson_id) > 0)
      {
          const lessonInfo = await readLessonStim(lesson_id, unit_id);
          
          setLessonAssetPath(ASSETS_BASE_PATH + `/level-${parseInt(props.match.params.level_id)}/unit-${parseInt(props.match.params.unit_id)}/lesson-${lessonInfo.sort_order}/`);
          const stimInfo = await getLessonS3Data(ASSETS_BASE_PATH + `/level-${parseInt(props.match.params.level_id)}/unit-${parseInt(props.match.params.unit_id)}/lesson-${lessonInfo.sort_order}/animations/stim.json`);
          
          getSubtitles(ASSETS_BASE_PATH + `/level-${parseInt(props.match.params.level_id)}/unit-${parseInt(props.match.params.unit_id)}/lesson-${lessonInfo.sort_order}/animations/`);

          let stim_data = null;
          if (stimInfo === false) {
            stim_data = JSON.parse(lessonInfo.stim_data);
          }
          else {
            stim_data = stimInfo;
          }

          let phonics_data = await getLessonS3Data(ASSETS_BASE_PATH + `/level-${parseInt(props.match.params.level_id)}/unit-${parseInt(props.match.params.unit_id)}/lesson-${lessonInfo.sort_order}/audio/phonic.json`);

          if (phonics_data && Object.keys(phonics_data).length > 0) {
            setNextUrl(`/levels/${parseInt(props.match.params.level_id)}/units/${parseInt(props.match.params.unit_id)}/lessons/${lesson_id}/phonics`);
          }
          else {
            setNextUrl(`/levels/${parseInt(props.match.params.level_id)}/units/${parseInt(props.match.params.unit_id)}/lessons/${lesson_id}/questions/1`);
          }

          setBackUrl(`/levels/${parseInt(props.match.params.level_id)}/units/${parseInt(props.match.params.unit_id)}/lessons/`);

          if ("animation" in stim_data) {
            setStimAnimationType(true)
            setStimAnimation(stim_data.animation);
            setStimData(stim_data);
          } else {
            const stim_images = stim_data.images;
            const stim_audio = stim_data.audiosrc;
            const stim_image = stim_data.coverimg;

            if(stim_images && stim_images.length <= 0) 
            {
              history.push(nextURL);
              return;
            } else {
              setStimImage(stim_image);
              setStimData(stim_data);
              setStimAudio(stim_audio);
              setStimAnimationType(false);
            }
          }
          window.timeout = setTimeout(function () 
          {
            if (refImage.current) {
              setImageHeight(refImage.current.clientHeight)
            }            
          }, 500);
      }        
      else {
        setErrorMessage("Invalid Lesson. Please select valid lesson");
      }

    }
    setWelcome();

    return () => {
      isMounted = false;
    };
  }, [history, props, nextURL])

  const getSubtitles = async(path) => {
    const subtitleInfo = await getSubtitlesS3Data(path + `transcript.txt`);
    if (!subtitleInfo) {
      // No subtitle was found, use old subtitle scripts
      setSubtitles(false);
    }
    else {
      let blob = new Blob( [ subtitleInfo ], { type: "text/plain" } );
      setSubtitles(URL.createObjectURL( blob ));
    }
  }
  
  const onSkipClicked = async () => 
  {
    history.push(nextURL);
  }

  const onBackClicked = async () => 
  {
    history.push(backURL);
  } 
  
  var audioPlayed = false;
  var current_timer = 0 ;
  var stimCounter = 0;
  var stim_images = "";

  function onPlayClicked () 
  {
    setAudioPlaying(true)
    
    if(audioPlayed === false)
    {
      audioPlayed = true
      let audio_file = lessonAssetPath + "audio/" + stimAudio + ".mp3" 
      const audio = new Audio(audio_file)
      audio.play()
    
      stim_images = Array.from(stimData.images);
      current_timer = 0
    }

    current_timer = current_timer + 100
   
    if(stim_images[0].time <= current_timer)
    {
      var image       =   stim_images[stimCounter].image
      var script      =   stim_images[stimCounter].text

      setStimImage(image)
      setStimScript(script)
      stim_images.shift(); 

      if(stim_images.length <= 0)
      clearTimeout(window.timeout)
    }

    if(stim_images.length > 0)
    {
      window.timeout =  setTimeout(function () 
      {
        onPlayClicked()
      }, 100);
    }
  }

  function pauseVideo() {
    if (audioPlaying) {
      setAudioPlaying(false);
      var vid = document.getElementById("stim-video");
      vid.pause();
    }
  }

  function playAnimation() 
  {
    setAudioPlaying(true);

    var vid = document.getElementById("stim-video");
    vid.play();
  }

  const onVideoUpdate = () => {
    let vid = document.getElementById("stim-video");
    let currentTime = parseFloat(vid.currentTime);
    let currentScript = "";

    for (let i = 0; i < stimData.text.length; ++i) {
      if (currentTime >= parseFloat(stimData.text[i].time)) {
        currentScript = stimData.text[i].text;
      }
    }

    setStimScript(currentScript);
  }

  const onVideoEnd = () => {
    setStimScript("");
    setAudioPlaying(false);
    setDone(true);
  }

  const onVideoReady = () => {
    if (subtitles !== false) {
      let video = document.getElementById("stim-video");

      let tracks = video.textTracks[0];
      tracks.mode = 'showing';
    }
  }

  if((animationStimType === true && stimAnimation.length === 0) || (animationStimType === false && (stimData.images && stimData.images.length === 0)))
    return null;

  const RawHTML = ({children, className = ""}) => 
< div className={className}
  dangerouslySetInnerHTML={{ __html: children.replace(/\n/g, '<br />')}} />

  return (
    <Container style={{display: "flex", flexDirection: "column", justifyContent: "space-between"}}>  
      {errorMessage &&
        <Row key="r0"> 
            <Col key="r0-c1">
                <Alert variant='danger'>
                    {errorMessage}
                </Alert>
            </Col>  
        </Row>
      }
      {animationStimType === false &&
      <Row key="r1"> 
        <Col xs={12} lg={6}  key="r1-c1">
           <div ref={refImage} className="lesson-welcome-images">
              <img src={`${lessonAssetPath}images/${stimImage}`} alt="Intro" style={{width: "100%" }} />
           </div>
        </Col>
        <Col xs={12} lg={6}  key="r1-c2">
           <div className="lesson-welcome-script" style={{minHeight: imageHeight}}>
              <RawHTML>{stimScript}</RawHTML> 
           </div>
        </Col>
      </Row>
      }
      {animationStimType === true && subtitles !== false &&
        <Row key="r1" className="video-container">
          <Col xs={12} lg={9}  key="r1-c1" style={{padding:"0px"}}>
            <div ref={refImage} className="lesson-welcome-images" onClick={pauseVideo}>
              <video playsInline id="stim-video" width={{width: "100%" }} height={{minHeight: "100%"}} onCanPlay={onVideoReady} className="stim-video" onTimeUpdate={onVideoUpdate} onEnded={onVideoEnd}>
                <source src={`${lessonAssetPath}animations/${stimAnimation}#t=0.5`} type="video/mp4" />
                <track label="English" kind="subtitles" srcLang="en" src={subtitles} default></track>
              </video>
              {!audioPlaying && 
                <button className="play-btn" onClick={animationStimType ? playAnimation : onPlayClicked}>
                <img className="play-btn-img" src={Img} alt="#" />
              </button>}
            </div>
          </Col>
          {/* <Col xs={12} lg={6}  key="r1-c2" style={{padding:"0px"}}>
            <div className="lesson-welcome-script" style={{minHeight: imageHeight}}>
                {audioPlaying &&
                  <RawHTML>{stimScript}</RawHTML>
                }
                {!audioPlaying &&
                  <img src={StimCover} alt="Intro" style={{width: "100%" }} />
                }
            </div>
          </Col> */}
        </Row>}  
      {animationStimType === true && subtitles === false &&
        <Row key="r1">
          <Col xs={12} lg={6}  key="r1-c1" style={{padding:"0px"}}>
            <div ref={refImage} className="lesson-welcome-images" onClick={pauseVideo}>
              <video playsInline id="stim-video" width={{width: "100%" }} height={{minHeight: "100%"}} onCanPlay={onVideoReady} className="stim-video" onTimeUpdate={onVideoUpdate} onEnded={onVideoEnd}>
                <source src={`${lessonAssetPath}animations/${stimAnimation}#t=0.5`} type="video/mp4" />
              </video>
              {!audioPlaying && 
                <button className="play-btn" onClick={animationStimType ? playAnimation : onPlayClicked}>
                  <img className="play-btn-img" src={Img} alt="#" />
                </button>}
            </div>
          </Col>
          <Col xs={12} lg={6}  key="r1-c2" style={{padding:"0px"}}>
            <div className="lesson-welcome-script" style={{minHeight: imageHeight}}>
                {audioPlaying &&
                  <RawHTML>{stimScript}</RawHTML>
                }
                {!audioPlaying &&
                  <img src={StimCover} alt="Intro" style={{width: "100%" }} />
                }
            </div>
          </Col>
        </Row>}  
        <Row key="r2" className="nav-container-footer">
         <Col xs={6} md={6} key="r2-c22" className="skip-btn-container" style={{textAlign:"center"}}>
          <Button
            variant="warning"            
            onClick={onBackClicked}
            className="lesson-stim-button"
            >
              <>
              <FaHome></FaHome> 
              </>
          </Button>
         </Col>
         <Col xs={6} md={6} key="r2-c21" className="skip-btn-container" style={{textAlign:"center"}}>
          <Button
            variant="warning"            
            onClick={onSkipClicked}
            className="lesson-stim-button"
            >
              {videoDone &&
                <>
                {localization.Practice} <FaArrowRight className={"arrow-bounce"}></FaArrowRight>
                </>
            }
            {!videoDone &&
              <>
                {localization.Skip} <FaArrowRight></FaArrowRight> 
              </>
            }
          </Button>
         </Col>
      </Row>  
    </Container>
  )
}
 
export default LessonWelcomeScreen
