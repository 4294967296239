import React from 'react';
import { useHistory  } from 'react-router-dom'
import { useState, useEffect } from 'react'


import { Modal} from 'react-bootstrap';

import checkmark_icon from '../assets/checkmark_icon.png';
import x_icon from '../assets/x_icon.png';
import clock_icon from '../assets/clock_icon.png';
import review_next_button from '../assets/review_next_button.png';
import review_back_button from '../assets/review_back_button.png';
import replay_button from '../assets/replay_button.png';

//Utilities
import { getInitialState, getUnitIdByLesson, getLevelIdByUnit, processReviewStats, getUserId, getUserLocalization }  from '../utils/DbHelpers';
import { getMinutesBetweenDates } from '../utils/Helpers';
import reward from '../assets/reward.png';

const ReviewScreen = (props) => 
{
    const history = useHistory()
    const [showReview, setShowReview] = useState(false)
    const [reviewStats, setReviewStats] = useState()
    const [lesson, setLesson] = useState()
    const [unit, setUnit] = useState()
    const [level, setLevel] = useState()
    const [user, setUser] = useState()
    const [prevDisabled, setPrev] = useState(false)
    const [nextDisabled, setNext] = useState(false)
    const [localization, setLocalization] = useState('');

    useEffect(() => 
    {
      const CreateReviewScreen = async () => 
      {
        const lesson     = parseInt(props.lesson);
        const user_id    = await getUserId();
        setUser(user_id);
        setNext(false);
        setPrev(false);
        
        let local = await getUserLocalization();
        setLocalization(local);

        var review_stats = await processReviewStats(lesson, user_id)  

        var time_taken = getMinutesBetweenDates(review_stats.start_time, review_stats.end_time, local)
        review_stats.time_taken = time_taken;   
        review_stats.lesson = lesson;

        let currentUnit = await getUnitIdByLesson(lesson);
        let level_id = await getLevelIdByUnit(currentUnit);
        setUnit(currentUnit);
        setLevel(level_id);

        // Check if next lesson is part of this unit, if not then disable next button
        const next_unit_id = getUnitIdByLesson((lesson + 1));
        const prev_unit_id = getUnitIdByLesson((lesson - 1));

        Promise.all([next_unit_id, prev_unit_id]).then((values) => {
          if (values[0] === 0)
            setNext(true);
          
          if (values[1] === 0)
            setPrev(true);
          
          setReviewStats(review_stats);
          setLesson(lesson);
          setShowReview(true);
        });

        // Check if previous lesson is part of this unit, if not then disable next button
      }
      CreateReviewScreen()
    }, [props, lesson, history])

    const checkDisabledButtons = async (new_lesson) => {
      const next_unit_id = getUnitIdByLesson((new_lesson + 1));
      const prev_unit_id = getUnitIdByLesson((new_lesson - 1));

      Promise.all([next_unit_id, prev_unit_id]).then((values) => {
        setNext(false);
        setPrev(false);

        // If the unit doesn't exist, disable the button
        if (values[0] === 0)
          setNext(true);
        
        if (values[1] === 0)
          setPrev(true);
      });
    }

    const handleReviewClose = async (response_id) => 
    {
        setShowReview(false)
        props.onCloseReview()
    } 

    const onReviewPrevClick = async (lesson) => 
    {
      if(lesson <= 1)
        return
      
      let next_lesson = lesson - 1;
      const unit_id = await getUnitIdByLesson(next_lesson);
      const level_id = await getLevelIdByUnit(unit_id);

      // Unit change
      if (unit_id !== unit && unit_id !== 0) {
        history.push(`/levels/${level_id}/units/${unit_id}/lessons/?review=true&lesson=${next_lesson}`);
      }

      var review_stats = await processReviewStats(lesson - 1, user)  
      var time_taken = getMinutesBetweenDates(review_stats.start_time, review_stats.end_time, localization)
      review_stats.time_taken = time_taken;   
      review_stats.lesson = lesson -1;

      setUnit(unit_id);
      setLevel(level_id);
      checkDisabledButtons(review_stats.lesson)
      setReviewStats(review_stats)
      setShowReview(true)
    } 

    const onReviewNextClick = async (lesson) => 
    {
      let initial_state = await getInitialState();
      let next_lesson = lesson + 1;
      const unit_id = await getUnitIdByLesson(next_lesson);
      const level_id = await getLevelIdByUnit(unit_id);

      if(initial_state[0].last_finished_lesson_id < next_lesson) {
        history.push(`/levels/${level_id}/units/${unit_id}/lessons/${next_lesson}/welcome`);
        return;
      }

      // Unit change
      if (unit_id !== unit && unit_id !== 0) {
        history.push(`/levels/${level_id}/units/${unit_id}/lessons/?review=true&lesson=${next_lesson}`);
      }

      let review_stats = await processReviewStats(lesson + 1, user);
      let time_taken = getMinutesBetweenDates(review_stats.start_time, review_stats.end_time, localization);

      review_stats.time_taken = time_taken;
      review_stats.lesson = lesson + 1;

      setUnit(unit_id);
      setLevel(level_id);
      checkDisabledButtons(review_stats.lesson)
      setReviewStats(review_stats);
      setShowReview(true);
    } 

    const onReviewReplayClick = async (lesson) => 
    {
      const new_unit_id          =   await getUnitIdByLesson(lesson)
      const new_level_id         =   await getLevelIdByUnit(new_unit_id)
      setShowReview(false)
      history.push(`/levels/${new_level_id}/units/${new_unit_id}/lessons/${lesson}/welcome`)      
      return
    }

    if(showReview === false)
      return null

    return (
        <Modal
        key="mr-modal"
        show={showReview}          
        keyboard={true}
        className="review_modal"
        centered
        onHide={handleReviewClose}
      >
      <Modal.Header closeButton>  
        <Modal.Title>              
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className="text-center">
          <div className="lesson-reward-container">
            <div key={`les${reviewStats.sort_order}`} className={`lesson-btn lesson-${reviewStats.sort_order} score-card-lesson-button`}>
              <img src={reward} className="lessonReward" alt="available-lesson"/>
            </div>
          </div>
          {/* <div key="mr-title" className={`header_title ${reviewStats.score_class}`}> {reviewStats.score_text}</div> */}
          <div className="review-card-header-container">
            <div className="review-card-title">{reviewStats.title}</div>
            <div>{`${localization.Level} ${level} - ${localization.Unit} ${unit} - ${localization.Lesson} ${reviewStats.sort_order}`}</div>
          </div>
          <div key="mr-content" className="review-content-block">
            <div key="mr-content-1" className='score-title'>{localization.Total} &nbsp;&nbsp; {reviewStats.correctQuestions} / {reviewStats.totalQuestions}</div>
            <div className="review-card-score-container">
              <div key="mr-content-2" className="score-blocks">
                  <img key="mr-content-21" className="score-icons" alt="checkmark" src={checkmark_icon} /> 
                  <span key="mr-content-22" className="score-text text-green">{reviewStats.correctQuestions}</span>
              </div>
              <div key="mr-content-6" className="score-blocks">
                  <img key="mr-content-21" className="score-icons" alt="checkmark" src={x_icon} /> 
                  <span key="mr-content-22" className="score-text text-danger">{reviewStats.totalQuestions - reviewStats.correctQuestions}</span>
              </div>
            </div>
            <div key="mr-content-3" className="score-blocks time-container">
                <div><img key="mr-content-31" className="score-icons" alt="clock" src={clock_icon} /></div>
                <div style={{textAlign: 'center', marginBottom: '20px'}}><span key="mr-content-32" className="score-text text-danger">{reviewStats.time_taken}</span></div>
            </div>
            <div key="mr-content-4" className="nav-icons">
                {!prevDisabled && <img key="mr-content-41" className="nav-icon-left" alt="previous" src={review_back_button}    onClick={() => onReviewPrevClick(reviewStats.lesson)}/> }
                {prevDisabled && <img key="mr-content-41" className="nav-icon-left nav-disabled" alt="previous" src={review_back_button} /> }
                <img key="mr-content-42" className="nav-icon-middle" alt="replay" src={replay_button}   onClick={() => onReviewReplayClick(reviewStats.lesson)} /> 
                {!nextDisabled && <div className="review-next-bounce"><img key="mr-content-43" className="nav-icon-right " alt="next" src={review_next_button}   onClick={() => onReviewNextClick(reviewStats.lesson)} /></div> }
                {nextDisabled && <img key="mr-content-43" className="nav-icon-right nav-disabled" alt="next" src={review_next_button} /> }
            </div>
          </div>
        </Modal.Body>          
      </Modal>
    );
  }

  export default ReviewScreen