
const versionNumber = "#1.5";

const Version = ({url}) => 
{

    function version() 
    {
        return  versionNumber;
    } 

    function isDev()  
    {
        let url = window.location.href;
        
        if(url.indexOf("ea-dev.ell") > -1 || url.indexOf("localhost") > -1){
            return true
        }else{
            return false;
        }
    } 
    
    return (
        version() +  (isDev() ? "(DEV)" : "")
      )
}
 
export default Version
